import React, { useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import "./SignUp.scss";
import { Link, useNavigate } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";

function SignUp() {
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const { signUp } = useAuth();

  const emailRef = useRef();
  const passwordRef = useRef();
  const displayName = useRef();
  const navigate = useNavigate();

  const userSignUp = async (emailRef, passwordRef, displayName, e) => {
    e.preventDefault();
    if (displayName === "") {
      setNameError(true);
    }
    if (emailRef === "") {
      setEmailError(true);
    }
    if (passwordRef === "") {
      setPasswordError(true);
    }
    await signUp(emailRef, passwordRef, displayName).then((data) => {
      setDoc(doc(db, "users", data.user.uid), { email: data.user.email });
    });
    // await signUp(emailRef, passwordRef, displayName);
    // console.log(data.user.email);
    navigate("/");
  };

  return (
    <form className="signup">
      <h1 className="signup__title">Please Sign Up...</h1>
      <div className="signup__section">
        <label className="signup__label">Name</label>
        <input
          className={
            !nameError ? "signup__input" : "signup__input signup__input--error"
          }
          ref={displayName}
          type="name"
        ></input>
      </div>
      <div className="signup__section">
        <label className="signup__label">Email</label>
        <input
          className={
            !emailError ? "signup__input" : "signup__input signup__input--error"
          }
          ref={emailRef}
          type="email"
        ></input>
      </div>
      <div className="signup__section">
        <label className="signup__label">Password</label>
        <input
          className={!passwordError ? "signup__input" : "signup__input--error"}
          ref={passwordRef}
          type="password"
        ></input>
      </div>
      <div className="signup__section">
        <label className="signup__label">Confirm Password</label>
        <input className="signup__input" type="password"></input>
      </div>
      <button
        className="signup__button"
        onClick={(e) =>
          userSignUp(
            emailRef.current.value,
            passwordRef.current.value,
            displayName.current.value,
            e
          )
        }
      >
        Sign Up
      </button>
      <h3 className="signup__text">
        Already have an account?{" "}
        <Link to="/login" className="signup__underline">
          Log in
        </Link>
      </h3>
    </form>
  );
}

export default SignUp;
