import "./GuideOptions.scss";

import React from "react";
import { useRef } from "react";

import likeArrow from "../../assets/icons/upvote.svg";
import unlikeArrow from "../../assets/icons/downvote.svg";
import commentsIcon from "../../assets/icons/comments.svg";
import shareArrow from "../../assets/icons/share-arrow.svg";

import { useCollection } from "../../hooks/useCollection";
import { db } from "../../firebase";
import {
  arrayUnion,
  doc,
  updateDoc,
  arrayRemove,
  getDoc,
  collectionGroup,
} from "firebase/firestore";

function GuideOptions({ guide, currentUser }) {
  const { documents: users } = useCollection("users");
  const likeRef = useRef();

  const likeGuide = async (id) => {
    // console.log("guide id: ", id);
    // console.log("current users id: ", currentUser.uid);
    const userList = users.find((user) => user.id === currentUser.uid);
    // console.log("userList: ", userList.likedGuides);

    // Find the liked guides for the current user
    if (userList.likedGuides.find((item) => item.id === guide.id)) {
      console.log("Guide already liked.");
      return;
    } else {
      // Add the guide.id to the liked guides array for current user
      await updateDoc(doc(db, "users", currentUser.uid), {
        likedGuides: arrayUnion({
          id: guide.id,
        }),
      });
      // Add +1 to guide.likes
      await updateDoc(doc(db, "guides", guide.id), {
        likes: parseInt(likeRef.current.innerText) + 1,
      });
      await refreshLikes(id);
      console.log("You liked this guide!");
    }
  };

  const unLike = async (id) => {
    const userList = users.find((user) => user.id === currentUser.uid);
    console.log("userList: ", userList.likedGuides);

    if (userList.likedGuides.find((item) => item.id === guide.id)) {
      await updateDoc(doc(db, "users", currentUser.uid), {
        likedGuides: arrayRemove({
          id: guide.id,
        }),
      });
      await updateDoc(doc(db, "guides", guide.id), {
        likes: parseInt(likeRef.current.innerText) - 1,
      });
      await refreshLikes(id);
      console.log("You unliked this guide!");
    } else {
      console.log("You can't unlike this.");
    }
  };

  const refreshLikes = async (id) => {
    const querySnapshot = await getDoc(doc(db, "guides", id));
    likeRef.current.innerText = await querySnapshot.data().likes;
    console.log(likeRef.current.innerText);
  };

  return (
    <div className="guide-options">
      <div className="guide-options__likes">
        <img
          onClick={() => likeGuide(guide.id)}
          className="guide-options__like"
          src={likeArrow}
          alt="up vote icon"
        />
        <p ref={likeRef} className="guide-options__bold">
          {guide.likes}
        </p>
        <img
          onClick={() => unLike(guide.id)}
          className="guide-options__unlike"
          src={unlikeArrow}
          alt="up vote icon"
        />
      </div>
      <div className="guide-options__comments">
        <img
          className="guide-options__icon"
          src={commentsIcon}
          alt="comment icon"
        />
        <p className="">Comments</p>
      </div>
      <div className="guide-options__share">
        <img
          className="guide-options__arrow"
          src={shareArrow}
          alt="share icon"
        ></img>
        <p>Share</p>
      </div>
      <div className="guide-options__more">
        <h4 className="guide-options__dots">...</h4>
      </div>
    </div>
  );
}

export default GuideOptions;
