import React from "react";
import "./Nav.scss";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useCollection } from "../../hooks/useCollection";
import { useAuth } from "../../context/AuthContext";
import upload from "../../assets/icons/upload.svg";
import bookmark from "../../assets/icons/bookmark-stroke.svg";

function Nav() {
  const { currentUser, signOut } = useAuth();
  const [searchText, setSearchText] = useState("");
  const [mobileActive, setMobileActive] = useState(false);

  // const [selectedCategory, setSelectedCategory] = useState("");
  let navigate = useNavigate();

  // console.log(currentUser);

  return (
    <>
      <div className="nav">
        <div className="nav__titles">
          <Link className="nav__logo" to="/">
            <h1 className="nav__title">GIF GUIDES</h1>
          </Link>
          <form
            className="nav__form"
            onSubmit={(e) => {
              e.preventDefault();
              navigate(`/search/${searchText}`);
            }}
          >
            <input
              onChange={(e) => setSearchText(e.target.value)}
              className="nav__searchbar"
              placeholder="Search how to..."
              name="search"
              type="search"
              id="search"
              autoComplete="off"
            ></input>
            <select
              value={""}
              onChange={(e) => navigate(`/category/${e.target.value}`)}
              className="nav__categories"
            >
              <option defaultValue="default">Category</option>
              <option value="cooking">Cooking</option>
              <option value="fitness">Fitness</option>
              <option value="art">Art</option>
            </select>
          </form>
          {/* <button
            className="nav__button"
            onClick={() => {
              navigate(`/search/${searchText}`);
            }}
          >
            Search
          </button> */}

          <div className="nav__actions">
            <Link to="/upload" className="nav__link">
              <img className="nav__upload" src={upload} alt="upload icon"></img>
            </Link>
            <Link to="/bookmarks" className="nav__bookmark">
              <img
                className="nav__bookmark nav__bookmark--icon"
                src={bookmark}
                alt="bookmark icon"
              ></img>
            </Link>
            {!currentUser && (
              <Link className="nav__link" to="/login">
                <p className="nav__login">Login</p>
              </Link>
            )}

            {currentUser && (
              <p className="nav__logout" onClick={() => signOut()}>
                Logout
              </p>
            )}
          </div>
          <button
            className="nav__hamburger"
            onClick={() => {
              setMobileActive(!mobileActive);
            }}
          >
            🍔
          </button>
        </div>
        {mobileActive && (
          <div className="nav__actions--mobile">
            <Link to="/upload" className="nav__link">
              <h3 className="nav__item arrow">Upload Guide</h3>
              {/* <img className="nav__upload" src={upload} alt="upload icon"></img> */}
            </Link>
            <Link to="/bookmarks" className="nav__link">
              <h3 className="nav__item arrow">Bookmarks</h3>
              {/* <img
                className="nav__bookmark nav__bookmark--icon"
                src={bookmark}
                alt="bookmark icon"
              ></img> */}
            </Link>
            {!currentUser && (
              <Link className="nav__link" to="/login">
                <p className="nav__item arrow">Login</p>
              </Link>
            )}

            {currentUser && (
              <p
                className="nav__item nav__item--logout"
                onClick={() => signOut()}
              >
                Logout
              </p>
            )}
          </div>
        )}
        {/* <p>{currentUser.Name}</p> */}
      </div>
    </>
  );
}

export default Nav;
