import { Link } from "react-router-dom";
import './CategoryList.scss'

export default function CategoryList({ categories }) {


  return (
    <div className="categories">
      <ul className="categories__list">
      {categories && categories.map((category, i) => {
          return (
            <div key={i} className="categories__card">
              <Link
                to={`/guides/${category.id}`}
                key={i}
                className="categories__link"
              >
                <h3 className="categories__title">{`${category.name}`}</h3>
                <img
                  className="categories__poster"
                  src={category.poster}
                  alt="guide cover"
                />
              </Link>
            </div>
          );
        })}
      </ul>
    </div>
  )
}