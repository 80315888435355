import { useAllGuides } from "../../hooks/useAllGuides";
import { useEffect, useState } from "react";
import "./HomePage.scss";
import { useAuth } from "../../context/AuthContext";
import { useCollection } from "../../hooks/useCollection";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import CategoryRow from "../../components/CategoryRow/CategoryRow";
// import getBookmarks from "../../utils/getBookmarkUtil";
import Guide from "../Guide/Guide";

export default function HomePage() {
  const { documents: categories } = useCollection("categories");
  const { documents: allGuides } = useAllGuides("guides");
  const { currentUser, signOut } = useAuth();
  // const [homepageBookmarks, setHomepageBookmarks] = useState([]);
  const [activeGuide, setActiveGuide] = useState(null);

  // useEffect(async () => {
  //   if (currentUser) {
  //     const bookmarks = await getBookmarks(currentUser.uid);
  //     setHomepageBookmarks(bookmarks);
  //   }
  // }, []);

  if (!allGuides) {
    return <p>Loading...</p>;
  }

  return (
    <div className="homepage">
      <div className="homepage__guides">
        {categories &&
          categories.map((category, index) => {
            return (
              <CategoryRow
                category={category}
                key={index}
                // homepageBookmarks={homepageBookmarks}
                setActiveGuide={setActiveGuide}
                allGuides={allGuides}
                currentUser={currentUser}
              />
            );
          })}
        <div className="homepage__section"></div>
      </div>
      {activeGuide && (
        <Guide
          guide={activeGuide}
          setActiveGuide={setActiveGuide}
          currentUser={currentUser}
        ></Guide>
      )}
    </div>
  );
}
