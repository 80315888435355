import { useState } from "react";
import { useParams } from "react-router-dom";
import { collection, query, where, getDocs, limit, orderBy, startAfter, getCountFromServer } from "firebase/firestore";

import { db } from "../firebase";
import { useEffect } from "react";

export const useSingleCategoryLimit = (id, limitValue, page, setNumOfPages) => {
  const [documents, setDocuments] = useState([]);
  const [lastVisible, setLastVisible] = useState();

  useEffect(() => {
    const getNumOfPages = async () => {
      const ref = collection(db, "guides");
      const q = query(ref, where("categoryName", "==", id.toLowerCase()));
      const snapshot = await getCountFromServer(q);
      setNumOfPages(Math.ceil(snapshot.data().count / limitValue));
    };
    getNumOfPages();
  }, []);

  useEffect(() => {
    const ref = collection(db, "guides");
    let q;
    if (lastVisible) {
      q = query(ref, where("categoryName", "==", id.toLowerCase()), orderBy("name"), startAfter(lastVisible), limit(limitValue));
    } else {
      q = query(ref, where("categoryName", "==", id.toLowerCase()), orderBy("name"), limit(limitValue));
    }

    getDocs(q).then((querySnapshot) => {
      if (querySnapshot.docs[querySnapshot.docs.length - 1] && querySnapshot.docs[querySnapshot.docs.length - 1].data()) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }

      const data = [];

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });

      setDocuments([...data]);
    });
  }, [page]);

  return { documents };
};
