import React, { useState } from "react";
import "./GuideCover.scss";
import GuideOptions from "../GuideOptions/GuideOptions";

import BookmarkStroke from "../../assets/icons/bookmark-stroke.svg";
import BookmarkFill from "../../assets/icons/bookmark-fill.svg";

import { useRef } from "react";
import { useCollection } from "../../hooks/useCollection";
import { db } from "../../firebase";

function GuideCover({
  guide,
  i,
  // isBookmarked,
  setActiveGuide,
  id,
  currentUser,
  activeGuide,
}) {
  // const [bookmarked, setBookmarked] = useState(isBookmarked);
  const { documents: users } = useCollection("users");
  const [activeComments, setActiveComments] = useState(null);

  // const addToBookmarks = async () => {
  //   console.log(bookmarked);
  //   console.log(isBookmarked);
  //   if (bookmarked) {
  //     setBookmarked(false);
  //     await updateDoc(doc(db, "users", currentUser.uid), {
  //       bookmarks: arrayRemove({
  //         id: guide.id,
  //       }),
  //     });
  //     console.log("Removed from bookmarks!");
  //   } else {
  //     await updateDoc(doc(db, "users", currentUser.uid), {
  //       bookmarks: arrayUnion({
  //         id: guide.id,
  //       }),
  //     });
  //     setBookmarked(true);
  //     console.log("Added to bookmarks!");
  //   }
  // };

  // console.log(currentUser.uid);
  // console.log(users[0].likedGuides.includes(guide.id));

  return (
    <div
      key={i}
      className={!id ? "guide-card" : "guide-card guide-card--category"}
    >
      <img
        className="guide-card__poster"
        onClick={() => {
          setActiveGuide({ ...guide });
        }}
        src={guide.coverGif}
        alt="guide cover"
      />
      <div className="guide-card__details">
        <div className="guide-card__info">
          <div className="guide-card__bin">
            <h4 className="guide-card__author">{`@${guide.author}`}</h4>
            <h3
              className="guide-card__title"
              onClick={() => {
                setActiveGuide({ ...guide });
              }}
            >{`${guide.name}`}</h3>
          </div>
          <GuideOptions guide={guide} currentUser={currentUser} />
          {/* <img
            onClick={() => addToBookmarks()}
            src={isBookmarked ? BookmarkFill : BookmarkStroke}
            className="guide-card__bookmark"
          ></img> */}
        </div>
      </div>
    </div>
  );
}
export default GuideCover;
