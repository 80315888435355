import algoliasearch from "algoliasearch/lite";
import "./SearchPage.scss";
import { Hits, InstantSearch, SearchBox } from "react-instantsearch-hooks-web";
import { useParams } from "react-router-dom";
import GuideCover from "../../components/GuideCover/GuideCover";

function Hit({ hit }) {
  return <GuideCover guide={hit} />;
}

function SearchPage() {
  const { id } = useParams();
  const searchClient = algoliasearch("N4VKI8MMTJ", "8d9b5838d8e0888cafb6acb2ae7bffeb");

  return (
    <div className="search-page">
      <InstantSearch
        searchClient={searchClient}
        indexName="guides"
        initialUiState={{
          guides: {
            query: id,
          },
        }}
      >
        <div className="homepage__main">
          <SearchBox submitIconComponent={({ classNames }) => <>Submit</>} />
        </div>
        <Hits className="test" hitComponent={Hit} />
      </InstantSearch>
    </div>
  );
}
export default SearchPage;
