import { useEffect, useState } from "react";
import "./StepCard.scss";

function StepCard({ step, i }) {
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    setCompleted(false);
  }, [step]);

  const clickHandler = () => {
    setCompleted(!completed);

    if (!completed) {
      document.getElementById(`step-${i + 2}`).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  return (
    <div key={i} id={`step-${i + 1}`} className={`step-card ${completed && "step-card--completed"}`}>
      <div className="step-card__step-wrapper">
        <p className="step-card__step">{`${step.step}`}</p>
      </div>
      <img className="step-card__gif" src={step.gif} alt="step"></img>

      <button className={`step-card__checkmark ${completed && "step-card__checkmark--completed"}`} onClick={clickHandler}>
        {completed ? "✅" : i + 1}
      </button>
    </div>
  );
}
export default StepCard;
