import React, { useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import "./Login.scss";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Login({ getLabels }) {
  const { login, currentUser } = useAuth();
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  const userLogin = async (emailRef, passwordRef, e) => {
    e.preventDefault();
    await login(emailRef, passwordRef);
    console.log("login successful", currentUser.name);
    alert("Login successful");
    navigate("/");
  };

  // useEffect(() => {
  //   userLogin();
  // }, []);

  return (
    <form className="login">
      <h1 className="login__title">Login</h1>
      <div className="login__section">
        <label className="login__label">Email</label>
        <input className="login__input" ref={emailRef} type="email"></input>
      </div>
      <div className="login__section">
        <label className="login__label">Password</label>
        <input
          className="login__input"
          ref={passwordRef}
          type="password"
        ></input>
      </div>
      <button
        className="login__button"
        onClick={(e) =>
          userLogin(emailRef.current.value, passwordRef.current.value, e)
        }
      >
        Login
      </button>
      <h3 className="login__text">
        Forgot your password?
        <span className="signup__underline">Resend Email</span>
      </h3>
      <h3 className="login__text">
        Need an account?{" "}
        <Link to="/signup" className="signup__underline">
          Sign Up
        </Link>
      </h3>
    </form>
  );
}

export default Login;
