import { useState } from "react";
import { Link } from "react-router-dom";
import { useSingleCategoryLimit } from "../../hooks/useSingleCategoryLimit";
import GuideCover from "../GuideCover/GuideCover";
import "./CategoryRow.scss";

function CategoryRow({
  category,
  setActiveGuide,
  homepageBookmarks,
  allGuides,
  currentUser,
}) {
  const [page, setPage] = useState(0);
  const [numOfPages, setNumOfPages] = useState();
  const limitValue = 3;

  const { documents: guides } = useSingleCategoryLimit(
    category.name,
    limitValue,
    page,
    setNumOfPages
  );
  return (
    <div className="cat-row">
      <Link
        to={`/category/${category.name}`}
        className="cat-row--title"
      >{`See all ${category.name} guides >>`}</Link>
      <div className="cat-row__scroll" id="scroller">
        <button
          className="cat-row__back"
          onClick={() => {
            setPage(page - 1);
          }}
          disabled={page === 0}
        >
          back
        </button>
        {guides.map((guide, i) => {
          // const isBookmarked = homepageBookmarks.includes(guide.id);
          return (
            <GuideCover
              key={i}
              guide={guide}
              i={i}
              // isBookmarked={isBookmarked}
              setActiveGuide={setActiveGuide}
              allGuides={allGuides}
              currentUser={currentUser}
            />
          );
        })}

        <button
          className="cat-row__next"
          onClick={() => {
            setPage(page + 1);
          }}
          disabled={!numOfPages || page + 1 === numOfPages}
        >
          next
        </button>
      </div>
    </div>
  );
}
export default CategoryRow;
