
import CategoryList from '../../components/CategoryList/CategoryList'
import { useCollection } from '../../hooks/useCollection'

export default function Categories() {
  const { documents: categories } = useCollection('categories')
    
  return (
    <div>
      {categories && <CategoryList categories={categories} />}
    </div>
  );
}
