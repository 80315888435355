import React from "react";
import { useState } from "react";
import "./Bookmarks.scss";
import { useAuth } from "../../context/AuthContext";
import { useAllGuides } from "../../hooks/useAllGuides";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import GuideCover from "../../components/GuideCover/GuideCover";
import getBookmarks from "../../utils/getBookmarkUtil";

function Bookmarks() {
  // const { documents: allGuides } = useAllGuides("guides");
  const { currentUser, signOut } = useAuth();
  const [userBookmarkIDs, setUserBookmarkIDs] = useState([]);
  const [allBookmarks, setAllBookmarks] = useState([]);

  useEffect(async () => {
    const bookmarks = await getBookmarks(currentUser.uid);
    bookmarks.forEach(async (bookmark) => {
      console.log(bookmark);
      const querySnapshot = await getDoc(doc(db, "guides", bookmark));
      setAllBookmarks((prevState) => [...prevState, querySnapshot.data()]);
    });
  }, []);

  if (!allBookmarks) {
    return <p>Loading..123...</p>;
  }
  return (
    allBookmarks &&
    allBookmarks.map((guide, i) => {
      return <GuideCover key={i} guide={guide} isBookmarked={true} />;
    })
  );
}

export default Bookmarks;
