import React, { useCallback } from "react";
import Dropzone from "react-dropzone";
import { useState, useEffect } from "react";

// Styles
import "./UploadGuide.scss";

// Firebase
import { doc, setDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const EMPTY_STEP = { step: "", gif: null };

function UploadGuide() {
  const [file, setFile] = useState("");
  const [url, setUrl] = useState("");
  const [percentage, setPercentage] = useState(null);
  const [guideSteps, setGuideSteps] = useState([EMPTY_STEP]);
  // const [currentStep, setCurrentStep] = useState(EMPTY_STEP);
  const [index, setIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(true);
  const [categoryValue, setCategoryValue] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const handleAddSteps = () => {
  //   setGuideSteps((prevState) => [...prevState, currentStep]);
  //   console.log(guideSteps);
  // };

  // console.log(guideSteps);

  // GIF uploader
  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (progress === 100) {
            progress = null;
          }
          setPercentage(progress);
          switch (snapshot.state) {
            case "paused":
              // console.log("Upload is paused");
              break;
            case "running":
              // console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUrl(downloadURL);
            let newGuideSteps = guideSteps.map((step, stepIndex) => {
              if (stepIndex === index) {
                return { ...step, gif: downloadURL };
              }
              return step;
            });

            setGuideSteps(newGuideSteps);
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  // console.log(file);
  // console.log(url);
  // console.log(currentStep);

  // When user submits guide, upload data to firecloud db and gifs to firebase storage.
  const handleAddGuide = async (e) => {
    e.preventDefault();
    const newId = uuidv4();

    await setDoc(doc(db, "guides", newId), {
      author: e.target.author.value,
      categoryId: categoryValue,
      categoryName: e.target.categoryName.value,
      coverGif: guideSteps[0].gif,
      id: newId,
      name: e.target.name.value,
      tags: e.target.tags.value,
      // steps: stepsArray
      steps: guideSteps,
      likes: 0,
    });
    alert("guide successfully uploaded");
  };

  return (
    <div className="upload">
      <div className="upload__section">
        <form onSubmit={handleAddGuide} className="upload__form">
          <div className="upload__card upload__card--info">
            <div className="upload__info">
              <div className="upload__inputs">
                <label className="upload__label">GUIDE NAME</label>
                <input
                  className="upload__input"
                  placeholder="How to..."
                  type="name"
                  name="name"
                  required
                  // value=""
                ></input>
              </div>
              <div className="upload__inputs">
                <label className="upload__label">AUTHOR</label>
                <input
                  className="upload__input"
                  type="author"
                  name="author"
                  required
                ></input>
              </div>
              <div className="upload__inputs">
                <label className="upload__label">CATEGORY</label>
                <select
                  name="categoryName"
                  className="upload__input upload__input--category"
                  value={categoryValue}
                  onChange={(event) => setCategoryValue(event.target.value)}
                  required
                >
                  <option value="100">Cooking</option>
                  <option value="200">Fitness</option>
                  <option value="300">Art</option>
                  <option value="400">Life-Hacks</option>
                  <option value="500">Fitness</option>
                  <option value="600">Beauty</option>
                </select>
              </div>
              <div className="upload__inputs">
                <label className="upload__label">TAGS</label>
                <input
                  name="tags"
                  className="upload__input upload__input--large"
                  placeholder="Add relevant tags to help people find your guide"
                ></input>
              </div>
            </div>
          </div>

          {guideSteps && (
            <div className="upload__card upload__card--steps">
              <div className="upload__info">
                <div className="upload__steps">
                  {guideSteps.map((step, stepIndex) => {
                    return (
                      <p
                        className={
                          index === stepIndex
                            ? "upload__step upload__step--active"
                            : "upload__step"
                        }
                        onClick={() => {
                          setIndex(stepIndex);
                        }}
                        key={stepIndex}
                      >
                        {stepIndex + 1}
                      </p>
                    );
                  })}
                  <div
                    className="upload__addstep"
                    onClick={() => {
                      let newGuideSteps = [...guideSteps];
                      newGuideSteps.push(EMPTY_STEP);
                      setGuideSteps([...newGuideSteps]);
                      setIndex(guideSteps.length);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Add Step
                  </div>
                </div>
              </div>

              <div className="upload__dropzone">
                <label
                  htmlFor="file"
                  style={{ cursor: "pointer" }}
                  className="upload__add"
                >
                  + GIF
                </label>

                <input
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  // style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  required
                ></input>
              </div>

              {percentage ? (
                <p>Loading...</p>
              ) : (
                <img className="upload__gif" src={guideSteps[index].gif}></img>
              )}

              <div className="upload__steptext">
                <label className="upload__label">Step {index + 1}</label>

                <input
                  className="upload__input upload__input--step"
                  value={guideSteps[index].step}
                  required
                  onChange={(e) => {
                    let newGuideSteps = guideSteps.map((step, stepIndex) => {
                      if (stepIndex === index) {
                        return { ...step, step: e.target.value };
                      }
                      return step;
                    });

                    setGuideSteps(newGuideSteps);
                  }}
                ></input>
              </div>
              <button className="upload__submit">Submit</button>
            </div>
          )}
          {/* <div className="upload__card upload__card--addstep">
          <div className="upload__inputs">
            <label className="upload__label">Step 1</label>
            <input
              onChange={(e) =>
                setCurrentStep({ ...currentStep, step: e.target.value })
              }
              className="upload__input upload__input--large"
              name="step"
            ></input>
          </div>
          <div className="upload__inputs">
            <div>
              <img src={file ? URL.createObjectURL(file) : ""}></img>
            </div>
            <label
              htmlFor="file"
              style={{ cursor: "pointer" }}
              className="upload__add"
            >
              + GIF
            </label>
            <input
              id="file"
              multiple
              onChange={(e) => setFile(e.target.files[0])}
              style={{ display: "none" }}
              type="file"
            ></input>
          </div>
        </div>

        <div className="upload__box">
          <div
            className="upload__add"
            onClick={handleAddSteps}
            style={{ cursor: "pointer" }}
          >
            Add Step
          </div>
        </div>
        <div className="upload__cta">
          <button
            disabled={percentage !== null && percentage < 100}
            type="submit"
            className="upload__submit"
            style={{ cursor: "pointer" }}
          >
            Upload
          </button>
        </div> */}
        </form>
      </div>
    </div>
  );
}

export default UploadGuide;
