import React from "react";
import { useEffect } from "react";
import "./CategoryPage.scss";
import { Link, useParams } from "react-router-dom";
import GuideCover from "../../components/GuideCover/GuideCover";
import { useSingleCategory } from "../../hooks/useSingleCategory";

function CategoryPage() {
  const { id } = useParams();

  const { documents: guides } = useSingleCategory("guides", id);

  useEffect(() => {}, []);

  return (
    <div className="cat-page">
      <h2 className="cat-page__title">{id.toUpperCase()}</h2>
      <div className="cat-page__rows">
        {guides &&
          guides.map?.((guide, i) => {
            return (
              <div className="cat-page__guide">
                <GuideCover guide={guide} i={i} id={id} />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default CategoryPage;
