import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "firebase/storage";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyDNb38s-SbytTMMfiGX7mmGSZ4Vv8aPNg0",
  authDomain: "gif-guides-official.firebaseapp.com",
  databaseURL: "gs://gif-guides-official.appspot.com",
  projectId: "gif-guides-official",
  storageBucket: "gif-guides-official.appspot.com",
  messagingSenderId: "924525745355",
  appId: "1:924525745355:web:32362baa31188856adb74e",
  measurementId: "G-MWEN8TDWTE",
};

const app = initializeApp(firebaseConfig);
export const userAuth = getAuth();
export const db = getFirestore(app);
export const storage = getStorage(app);
