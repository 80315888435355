import { useState } from "react";
import { useParams } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";

import { db } from "../firebase";
import { useEffect } from "react";

export const useSingleCategory = (guides, id) => {
  const [documents, setDocuments] = useState([]);

  // console.log("The params:", id)

  useEffect(() => {
    const ref = collection(db, "guides");
    const q = query(ref, where("categoryName", "==", id.toLowerCase()));

    getDocs(q).then((querySnapshot) => {
      const data = [];

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });

      setDocuments([...data]);
    });
  }, [id]);

  return { documents };
};
