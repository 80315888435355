import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
// components
import Nav from "./components/Nav/Nav";
import Categories from "./pages/Categories/Categories";
import Guide from "./pages/Guide/Guide";
import HomePage from "./pages/HomePage/HomePage";
import UploadGuide from "./pages/UploadGuide/UploadGuide";
import SignUp from "./pages/SignUp/SignUp";
import Login from "./pages/Login/Login";
import Bookmarks from "./pages/Bookmarks/Bookmarks";
import { useContext } from "react";
import { AuthProvider } from "./context/AuthContext";
import SearchPage from "./pages/SearchPage/SearchPage";
import CategoryPage from "./pages/CategoryPage/CategoryPage";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Nav />
          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/categories" element={<Categories />} />
            <Route path="/guide/:id" element={<Guide />} />
            <Route path="/category/:id" element={<CategoryPage />} />
            <Route path="/upload" element={<UploadGuide />} />
            <Route path="/search/" element={<SearchPage />} />
            <Route path="/search/:id" element={<SearchPage />} />
            <Route path="/bookmarks" element={<Bookmarks />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
