import React, { useEffect } from "react";
import "../Guide/Guide.scss";
import { useGuide } from "../../hooks/useGuide";
import VerifiedIcon from "../../assets/icons/verified_blue_24dp.svg";
import ScrollToTop from "react-scroll-to-top";
import StepCard from "../../components/StepCard/StepCard";
import Comments from "../../components/Comments/Comments";
import GuideOptions from "../../components/GuideOptions/GuideOptions";

function Guide({ guide, setActiveGuide, currentUser }) {
  // const { documents: guide } = useGuide();
  useEffect(() => {
    var div = document.getElementById("guide");
    div.scrollTop = 0;
  }, [guide]);

  return (
    <section id="guide" className="guide">
      <p
        className="guide__close"
        onClick={() => {
          setActiveGuide(null);
        }}
      >
        🆇
      </p>
      <div className="guide__details">
        <h3 className="guide__title">{`${guide.name}`}</h3>
        <div className="guide__info">
          <div className="guide__credentials">
            <img
              className="guide__verified"
              src={VerifiedIcon}
              alt="verified check-mark"
            />
            <p className="guide__author"> {`${guide.author}`}</p>
          </div>
          {/* <div className="guide__stats">
            <img className="guide__heart" src={HeartIcon} alt="Heart emoji" />
            <p className="guide__likes">{`${this.state.selectedGuide.likes} Likes`}</p>
          </div> */}
        </div>
      </div>
      <div className="guide__intro">
        <div className="guide__bin">
          <h2>Getting started</h2>
          <div className="guide__ingredients">
            <h3>Here's what you'll need to complete this guide: </h3>
            <ul className="guide__list">
              <li className="guide__item">item 1</li>
              <li className="guide__item">item 2</li>
              <li className="guide__item">item 3</li>
              <li className="guide__item">item 4</li>
            </ul>
          </div>
          <GuideOptions guide={guide} currentUser={currentUser} />
        </div>
      </div>

      <div className="guide__cards">
        {guide.steps?.map((step, i) => {
          return <StepCard step={step} key={i} i={i} />;
        })}
      </div>
      <div className="guide__social">
        <h3 className="guide__cta">Like this guide? Share it!</h3>
      </div>
      <ScrollToTop smooth />
    </section>
  );
}

export default Guide;
