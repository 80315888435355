import { createContext, useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail, updateProfile } from "firebase/auth";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyDNb38s-SbytTMMfiGX7mmGSZ4Vv8aPNg0",
  authDomain: "gif-guides-official.firebaseapp.com",
  databaseURL: "gs://gif-guides-official.appspot.com",
  projectId: "gif-guides-official",
  storageBucket: "gif-guides-official.appspot.com",
  messagingSenderId: "924525745355",
  appId: "1:924525745355:web:32362baa31188856adb74e",
  measurementId: "G-MWEN8TDWTE",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const AuthContext = createContext();
export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signOut() {
    console.log("You've signed out!");
    return auth.signOut();
  }
  function signUp(email, password, displayName) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function getUser() {
    return auth.currentUser;
  }

  const forgotPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
    console.log("Email has been sent to reset your pw!");
  };

  const updateUser = (name, avatar) => {
    updateProfile(auth.currentUser, {
      displayName: name,
      photoURL: avatar,
    });
  };

  // function isAdmin() {
  //   return auth.currentUser.getIdTokenResult().then((idTokenResult) => {
  //     if (!!idTokenResult.claims.admin) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  // }
  // function isEditor() {}
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);
  const value = {
    currentUser,
    getUser,
    login,
    signOut,
    signUp,
    updateUser,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
